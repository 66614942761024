import React, { Component } from "react";
import { FormattedMessage, injectIntl, FormattedNumber } from "react-intl";
import Layout from "../shared/Layout";
import Services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import moment from "moment";
import HeaderTitle from "../shared/HeaderTitle";
import { getLocalDateTime } from "../../util";
import Table from "../shared/Table";
import ExportToExcel from "../shared/ExportToExcel";

export class ContactusList extends Component {
  constructor() {
    super();
    this.inquiryKey = {
      "/customer-care": "care",
      "/marketing": "marketing",
      "/career": "career",
      "/service-signup": "service-signup",
      "/delete-account": "delete-account",
      "/checkedin-vehicles": "vehicle-checkin",
    };

    this.isVehicleCheckin = false;
  }

  state = {
    data: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    approvalFilter: "all",
    serviceFilter: "all",
    rejectingId: "",
    aplhabeticalOrder: true,
    count: 0,
    loading: false,
    forSignupService: false,
    services: [],
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        pageIndex: -1,
        data: [],
        hasMore: true,
        disableFlag: true,
      });
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = {
          progressBar: true,
          fromDate: this.state.from
            ? moment(this.state.from).startOf("day").toString()
            : null,
          toDate: this.state.to
            ? moment(this.state.to).endOf("day").toString()
            : null,
        };
        const pageData = await Services.getContactus(
          this.state.searchFilter,
          this.state.approvalFilter,
          this.state.serviceFilter,
          this.state.pageIndex,
          this.state.pageSize,
          this.state.aplhabeticalOrder,
          this.inquiryKey[this.props.location.pathname],
          options
        );

        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData?.response?.data),
            forSignupService: pageData?.response?.forServiceSignup || false,
            hasMore: pageData?.response?.data.length > 0,
            disableFlag: false,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      serviceFilter: this.serviceSelect.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  async componentDidMount() {
    this.isVehicleCheckin =
      this.props.location.pathname === "/checkedin-vehicles";

    const response = await Services.getUpcomingServices();
    this.setState({ services: response?.response });
  }

  resetPage(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: "",
      approvalFilter: "all",
      serviceFilter: "all",
      disableFlag: true,
    });
    if (this.state.forSignupService) {
      this.serviceSelect.value = "all";
    }
    e.preventDefault();
  }

  onExport = async (startDate, endDate) => {
    if (!startDate) return;
    if (!endDate) endDate = startDate;

    try {
      this.setState({ loading: true, exportExcelLoading: true });
      await Services.downloadContactList(
        this.state.serviceFilter,
        this.inquiryKey[this.props.location.pathname],
        appState.state.language,
        moment(startDate)
          .utcOffset(60 * 3)
          .startOf("day")
          .toString(),
        moment(endDate)
          .utcOffset(60 * 3)
          .endOf("day")
          .toString()
      );
    } catch (err) {
      console.err(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <Layout loading={this.state.loading}>
          <HeaderTitle
            id="contactus.contactus"
            defaultMessage="Customer Queries"
          />
          <div className="main-container">
            <div className="table-header clearfix">
              <form className="row" onSubmit={this.resetData.bind(this)}>
                {this.state.forSignupService && (
                  <div className="col-md-2 col-sm-4 col-xs-6">
                    <select
                      ref={(c) => {
                        this.serviceSelect = c;
                      }}
                      onChange={this.resetData.bind(this)}
                      className="form-control"
                    >
                      <option value="all">
                        {this.props.intl.formatMessage({
                          id: "contact.service",
                          defaultMessage: "Service",
                        })}
                      </option>
                      {this.state.services?.length > 0 &&
                        this.state.services.map((s) => (
                          <option value={s?._id}>
                            {s?.name["value_" + appState.state.language]}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
                <div className="col force--inline">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <ExportToExcel onExport={this.onExport} />
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-12">
                  <FormattedMessage
                    id="global.totalCount"
                    defaultMessage="Total Count"
                  />
                  <strong>
                    {" "}
                    <FormattedNumber
                      style="decimal"
                      maximumFractionDigits={0}
                      minimumFractionDigits={0}
                      value={this.state.data.length}
                    />
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <div className="table-responsive">
                <Table
                  loadMore={this.loadMore.bind(this)}
                  hasMore={this.state.hasMore}
                  caption={
                    (!this.state.data || this.state.data.length == 0) && (
                      <caption
                        style={{
                          captionSide: "bottom",
                          textAlign: "center",
                          margin: "20px",
                          color: "darkgray",
                        }}
                      >
                        <FormattedMessage
                          id="global.noData"
                          defaultMessage="No data available"
                        />
                      </caption>
                    )
                  }
                  headerRow={
                    <tr>
                      <th>
                        <FormattedMessage
                          id={
                            !this.isVehicleCheckin
                              ? "contactus.userName"
                              : "contactus.bookingRef"
                          }
                          defaultMessage={
                            !this.isVehicleCheckin ? "Name" : "Booking Ref"
                          }
                        />
                      </th>
                      {!this.isVehicleCheckin && (
                        <th>
                          <FormattedMessage
                            id="contactus.email"
                            defaultMessage="Email"
                          />
                        </th>
                      )}
                      {this.state.forSignupService && (
                        <th>
                          <FormattedMessage
                            id="contactus.service"
                            defaultMessage="Service"
                          />
                        </th>
                      )}
                      <th>
                        <FormattedMessage
                          id="contactus.phoneNumber"
                          defaultMessage="Phone Number"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="global.createdAt"
                          defaultMessage="Created At"
                        />
                      </th>
                    </tr>
                  }
                >
                  {this.state.data.map((c) => {
                    return (
                      <tr
                        key={c.id}
                        onClick={() =>
                          this.props.navigate(`/contact-query/${c.id}`, {
                            state: {
                              backUrl: this.props.location.pathname,
                            },
                          })
                        }
                        className="cases-trow link-row"
                      >
                        <td>{c.userName || c.bookingRef}</td>
                        {!this.isVehicleCheckin && <td>{c.email}</td>}
                        {this.state.forSignupService ? (
                          <td>
                            {
                              c.service?.name[
                                "value_" + appState.state.language
                              ]
                            }
                          </td>
                        ) : (
                          <></>
                        )}
                        <td className="direction">
                          {c.phoneNumber ? c.phoneNumber : ""}
                        </td>
                        <td>{getLocalDateTime(c.createdAt)}</td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(ContactusList));
